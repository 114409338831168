import React, { useEffect, useState, useContext, useCallback } from 'react';

import { formatNumber } from 'shared/formatters';
import { Box, Paper, Typography } from '@material-ui/core';
import { SummaryContext } from 'tracking/template/summary.context';
import ArrivalsCard from 'information/cards/arrivals-card.container';
import AlertStatusHeader from 'shared/alert-status-header';
import { Skeleton } from '@material-ui/lab';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import DrawerContainer from '../drawer';
import getTripTransferStops from '../arrivals.service';
import { TEXT, TEXT_ALERT_STATUS_HEADER } from '../arrivals.constants';
import ArrivalsTransferComponent from './arrivals-transfers.component';
import { TABLE_MSG_ERROR_ARRIVALS } from './arrivals-transfers.constants';

function ArrivalsTransferContainer() {
  const [tripTransferStops, setTripTransferStops] = useState([]);
  const [totalPackages, setTotalPackages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedUnitLoad, setSelectedUnitLoad] = useState({});
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [
    errorFetchTripTransferStops,
    setErrorFetchTripTransferStops
  ] = useState(false);

  const aggregatedPackages = useCallback((total = 0) => {
    setTotalPackages(total);
  }, []);

  const aggregatedVehicles = useCallback((total = 0) => {
    setTotalVehicles(total);
  }, []);

  useEffect(() => {
    getTripTransferStops()
      .then(currentTripTransferStops => {
        setTripTransferStops(currentTripTransferStops);
        setLoading(false);
      })
      .catch(() => {
        setErrorFetchTripTransferStops(true);
      });
  }, [setLoading]);

  const handleOpenDrawer = selectedInfo => {
    setSelectedUnitLoad(selectedInfo);
    setOpenDrawer(!openDrawer);
  };
  const { setSummary } = useContext(SummaryContext);

  useEffect(() => {
    setSummary(
      <>
        <Typography variant="h4">
          <strong>{TEXT.TITLE}</strong>
        </Typography>
        {!errorFetchTripTransferStops && (
          <>
            {loading ? (
              <Skeleton variant="text" width={200} height={40} />
            ) : (
              <Typography variant="h4">
                {formatNumber(totalPackages)}
                {TEXT.PACKAGES}
                {totalVehicles}
                {TEXT.VEHICLE}
              </Typography>
            )}
          </>
        )}
      </>
    );
  }, [
    errorFetchTripTransferStops,
    loading,
    setSummary,
    totalPackages,
    totalVehicles
  ]);

  return (
    <>
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        mb={5}
        style={{ borderRadius: 10 }}
      >
        {errorFetchTripTransferStops ? (
          <ConfigurableTableError
            TEXT_TABLE={{ MSG_ERROR: TABLE_MSG_ERROR_ARRIVALS }}
          />
        ) : (
          <>
            <AlertStatusHeader
              textPrimary={TEXT_ALERT_STATUS_HEADER.CARD.PRIMARY}
              textSecondary={TEXT_ALERT_STATUS_HEADER.CARD.SECONDARY}
            />
            <Box minHeight={44} p={5.5}>
              <ArrivalsCard
                sumTotalPackages={aggregatedPackages}
                withPickup={false}
                internalCard
                sumTotalVehicles={aggregatedVehicles}
              />
            </Box>
          </>
        )}
      </Paper>

      {openDrawer && (
        <DrawerContainer
          handleClosingDrawer={() => setOpenDrawer(false)}
          selectedUnitLoad={selectedUnitLoad}
        />
      )}
      {!errorFetchTripTransferStops && (
        <ArrivalsTransferComponent
          tripTransferStops={tripTransferStops}
          totalPackages={totalPackages}
          loadingTable={loading}
          handleOpenDrawer={handleOpenDrawer}
        />
      )}
    </>
  );
}

export default ArrivalsTransferContainer;
