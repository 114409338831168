import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Box, Divider, ListItem } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import useStylesDrawer from 'view/molecules/package-drawer/drawer.style';
import showSnackbar from 'shared/snackbar';
import { playError } from 'tracking/sound/index';
import { generateLabelImageFromLicensePlate } from './drawer-group-actions.service';
import TEXT, { LICENSE_PLATE_NOT_FOUND } from './constants';

export default function DrawerPrintLabelGroupComponent({
  onCloseDrawer,
  dataGroup
}) {
  const classes = useStylesDrawer();
  const { enqueueSnackbar } = useSnackbar();

  const handlePrintLabel = async () => {
    try {
      const { licensePlate } = dataGroup;
      // eslint-disable-next-line react/prop-types
      const licensePlatePostProcessed = licensePlate.replace(/\s/g, '_');
      const labelBase64 = await generateLabelImageFromLicensePlate({
        licensePlatePostProcessed
      });
      const printWindow = window.open('', '_blank');
      const labelContent =
        `<div style="display: flex; justify-content: center; align-items: center; height: 100vh;">` +
        `<div style="text-align: center;">` +
        `<p>${licensePlate}</p>` +
        `<img src="data:image/png;base64,${labelBase64}" alt="Red dot" />` +
        `</div>` +
        `</div>`;
      printWindow.document.write(labelContent);
      printWindow.document.close();
      printWindow.onafterprint = printWindow.close;
      printWindow.print();
    } catch (err) {
      const errorMessage =
        err.code === 404
          ? LICENSE_PLATE_NOT_FOUND(err.licensePlateRead)
          : err.message;
      playError();
      showSnackbar({
        message: errorMessage,
        variant: 'error',
        enqueueSnackbar
      });
    }
  };

  return (
    <CustomDrawer open>
      <DrawerHeader showDivider={false} handleClosingDrawer={onCloseDrawer}>
        <Typography variant="body2" className={classes.smoke}>
          {TEXT.GROUP_PACKAGES_DEFAULT}
        </Typography>

        <Typography variant="h4" className={classes.bolder}>
          {`${dataGroup.licensePlate} - ${TEXT.TO_TRANSFER}`}
        </Typography>

        <Typography variant="h5">{`${dataGroup.quantityPackages} ${
          TEXT.PACKAGES
        }`}</Typography>
      </DrawerHeader>
      <DrawerContent lessPadding>
        <Divider />
        <ListItem disableGutters button onClick={handlePrintLabel}>
          <Box
            my={2.5}
            px={2.5}
            display="flex"
            flexGrow={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">{TEXT.PRINT_GROUP_TICKET}</Typography>
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </ListItem>
        <Divider />
      </DrawerContent>
    </CustomDrawer>
  );
}

DrawerPrintLabelGroupComponent.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number
  }).isRequired
};
