export const PERMISSION_DENIED_MESSAGE =
  'Eita, você não consegue fazer essa alteração. Fale com o administrador para ter acesso.';
export const NOT_FOUND_MESSAGE =
  'Não encontramos esse endereço. Tente novamente.';
export const DEFAULT_MESSAGE =
  'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [003-Error-DEFAULT]';
export const BAD_REQUEST_MESSAGE =
  'Algo deu errado e não foi possível fazer essa alteração. Tente de novo daqui a pouco ou atualize a página.';

export const RESPONSE_MESSAGES_BY_CODE = {
  400: `${BAD_REQUEST_MESSAGE}[400]`,
  401: `${DEFAULT_MESSAGE}[401]`,
  403: `${PERMISSION_DENIED_MESSAGE}[403]`,
  404: `${NOT_FOUND_MESSAGE}[404]`,
  500: `${DEFAULT_MESSAGE}[500]`,
  502: `${DEFAULT_MESSAGE}[502]`,
  504: `${DEFAULT_MESSAGE}[504]`
};

/**
 * This helper intends to translate generic
 * HTTP error codes to user friendly messages.
 *
 * @param {int} statusCode HTTP status code
 * @param {object} customMessages Custom message mapping
 */
export const translateStatusCode = (
  statusCode,
  customMessages = {},
  responseData = [],
  customMessageByMessage = []
) => {
  const messageCustom = customMessageByMessage.find(customMessage => {
    return (
      (Array.isArray(responseData) &&
        responseData.some(item => customMessage.messageOrigin.test(item))) ||
      customMessage.messageOrigin.test(responseData)
    );
  });

  if (messageCustom?.messageParsed) {
    const messageOrigin = Array.isArray(responseData)
      ? responseData.find(item => messageCustom.messageOrigin.test(item))
      : responseData;

    return messageCustom.messageParsed.length > 0
      ? messageCustom.messageParsed(messageOrigin)
      : messageCustom.messageParsed();
  }

  // Merges messages objects into default one, avoiding manipulating original objects
  const responseMessage = {
    ...RESPONSE_MESSAGES_BY_CODE,
    ...customMessages
  };
  return responseMessage[statusCode] || DEFAULT_MESSAGE;
};

/**
 * High order function that translates request error into status code
 *
 * @param {Object.<number, string>} [customErrors={}] - contains custom HTTP status codes
 * @returns {function} - returns a rejected promise
 *
 * @param {Object} error
 * @param {Object} error.response
 * @param {number} error.response.status
 */
export const parseRequestError = (customErrors = {}) => error => {
  // get status code from response error
  const statusCode = ((error || {}).response || {}).status;
  const errorMessage = translateStatusCode(statusCode, customErrors);

  const errorResponse = {
    message: errorMessage,
    status: statusCode
  };

  return Promise.reject(errorResponse);
};
