import api from 'infra/api/leve';
import {
  GET_UNIT_LOAD_URL,
  MOVE_PACKAGES_BULK_URL,
  URL_PROXY_V1_PREFIX
} from 'infra/service/constants';
import { RESPONSE_MESSAGES_BY_CODE } from 'app/httpMessage';
import { translateStatusCode, DEFAULT_MESSAGE } from 'tracking/httpMessage';

const appName = 'arco';

export const getUnitLoadInfo = licensePlate =>
  api
    .get(`${GET_UNIT_LOAD_URL}?license_plate=${licensePlate}`)
    .then(({ data }) => data)
    .catch(error => {
      const responseError = {
        code: error.response ? error.response.status : null,
        message: error.response
          ? translateStatusCode(
              error?.response?.status,
              RESPONSE_MESSAGES_BY_CODE
            )
          : DEFAULT_MESSAGE
      };
      throw responseError;
    });

export const movePackageInBulk = async ({ packageIds, sortingContextLpn }) => {
  try {
    const sortingContext = await api.post(
      `${URL_PROXY_V1_PREFIX}/base/sorting_context`,
      {
        licensePlate: sortingContextLpn
      }
    );

    const destinationLpn =
      sortingContext.data.sortingContext.decisions[0].destination.licensePlate;

    return await api.post(MOVE_PACKAGES_BULK_URL, {
      packageIds,
      destinationUnitLoadLicensePlate: destinationLpn,
      sortingContextLpn,
      sideEffectParams: {
        applicationName: appName
      }
    });
  } catch (error) {
    const responseError = {
      code: error.response ? error.response.status : null,
      message: error.response
        ? translateStatusCode(error.response.status, RESPONSE_MESSAGES_BY_CODE)
        : DEFAULT_MESSAGE
    };

    throw responseError;
  }
};

export const generateLabelImageFromLicensePlate = async ({ licensePlate }) => {
  return api
    .get(`/api/v1/generate?licensePlate=${licensePlate}`)
    .then(async response => response.data.labelBase64)
    .catch(error => {
      const responseError = {
        code: error.response ? error.response.status : null,
        licensePlateRead: licensePlate,
        message: error.response
          ? translateStatusCode(
              error?.response?.status,
              RESPONSE_MESSAGES_BY_CODE
            )
          : DEFAULT_MESSAGE
      };

      throw responseError;
    });
};

export default movePackageInBulk;
