import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  ListItem,
  ListItemText,
  MenuList,
  Box,
  Typography
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PersonIcon from '@material-ui/icons/Person';
import VersionLabel from 'view/molecules/version';
import useStyles from 'profile/profile.style';
import { TEXT, PROFILE_SUBMENU } from 'profile/profile.constants';
import TEXT_FAIL from './profile-initital.constants';

const ProfileInitial = ({
  dcAbbrev,
  dcName,
  userName,
  setProfileState,
  handleLogout,
  hasMoreMultipleChoices,
  fetchDCFail
}) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.list}>
      <Box mt={1}>
        <Box
          m={2}
          ml={3.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          bgcolor="primary.main"
          fontSize="12px"
          borderRadius={50}
          height="40px"
          width="40px"
        >
          {dcAbbrev || <PersonIcon />}
        </Box>
        {fetchDCFail && (
          <Box m={2} ml={3.5} maxWidth="140px">
            {TEXT_FAIL.FAIL_LABEL}
            <Button
              data-testid="logout"
              onClick={() => window.location.reload()}
              variant="outlined"
              color="primary"
            >
              {TEXT_FAIL.FAIL_LABEL_BTN}
            </Button>
          </Box>
        )}
        <ListItem>
          <ListItemText>
            <em>{`${TEXT.PROFILE.BEFORE_NAME} ${userName}${
              TEXT.PROFILE.AFTER_NAME
            }`}</em>
          </ListItemText>
        </ListItem>
        <ListItem divider>
          <Grid container alignItems="center" justify="space-between">
            <Typography variant="caption">
              <Box component="span" fontWeight={300}>
                {`Base ${dcName} • `} <VersionLabel />
              </Box>
            </Typography>
          </Grid>
        </ListItem>
        {hasMoreMultipleChoices &&
          PROFILE_SUBMENU.map(item => {
            return (
              <ListItem
                key={item.name}
                divider
                button
                onClick={() => setProfileState(item.flows)}
              >
                <Grid container alignItems="center" justify="space-between">
                  <ListItemText>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="50px"
                      fontWeight={400}
                    >
                      {item.name}
                    </Box>
                  </ListItemText>
                  <ArrowForwardIosIcon color="primary" fontSize="small" />
                </Grid>
              </ListItem>
            );
          })}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50px"
        >
          <Button
            data-testid="logout"
            onClick={handleLogout}
            variant="text"
            color="primary"
          >
            {TEXT.PROFILE.LOGOUT}
          </Button>
        </Box>
      </Box>
    </MenuList>
  );
};

export default ProfileInitial;

ProfileInitial.propTypes = {
  dcAbbrev: PropTypes.string,
  dcName: PropTypes.string,
  userName: PropTypes.string,
  setProfileState: PropTypes.func.isRequired,
  handleLogout: PropTypes.func,
  hasMoreMultipleChoices: PropTypes.bool.isRequired,
  fetchDCFail: PropTypes.bool
};

ProfileInitial.defaultProps = {
  dcAbbrev: '',
  dcName: '',
  userName: '',
  handleLogout: () => {},
  fetchDCFail: false
};
