/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import * as Sentry from '@sentry/browser';

import ProfileInitalContainer from './profile-initial';
import ProfileChangeBaseContainer from './profile-change-base';
import ProfileComponent from './profile.component';
import { FLOWS } from './profile.constants';
import {
  getSelectedBase,
  setSelectedBase,
  fetchAndForceOrSelectFirst
} from './profile.service';

const ProfileContainer = () => {
  const [profileState, setProfileState] = useState(FLOWS.PROFILE_INITIAL);
  const [user, setUser] = useState();
  const [data, setData] = useState([]);
  const [dc, setDc] = useState({});
  const [fetchDCFail, setFetchDcFail] = useState(false);

  const [hasMoreMultipleChoices, setHasMoreMultipleChoices] = useState(false);

  const {
    state: { authenticatedUser, loading }
  } = useAmplifyAuth();

  const isInitialBase = profileState === FLOWS.PROFILE_INITIAL;
  const isChangeBase = profileState === FLOWS.CHANGE_BASE;

  useEffect(() => {
    const fetch = async () => {
      const dataResponse = await fetchAndForceOrSelectFirst();
      setHasMoreMultipleChoices(
        dataResponse.lastmilecompanyDistributioncenter?.length > 1
      );
      setData(dataResponse.lastmilecompanyDistributioncenter);

      const base = getSelectedBase();
      // if there is a selected base, set it to dc const
      if (base) {
        setDc(base);
      }
    };

    fetch().catch(() => {
      setFetchDcFail(true);
    });
  }, []);

  useEffect(() => {
    if (!loading && authenticatedUser) {
      setUser(authenticatedUser?.name?.split(' ', 1).toString());
      Sentry.setUser({ email: authenticatedUser?.email });
    }
  }, [loading, authenticatedUser]);

  const onChooseBase = item => {
    setDc(item);
    setProfileState(FLOWS.PROFILE_INITIAL);
    setSelectedBase(item);
    const split = window.location.pathname.split('/');
    split[1] =
      item.distribution_center?.routing_code ||
      item.last_mile_company.identification;
    window.history.pushState(null, null, split.join('/'));
    window.location.reload();
  };

  return (
    <ProfileComponent
      dcAbbrev={dc?.distribution_center?.routing_code}
      setProfileState={setProfileState}
    >
      {isInitialBase && (
        <ProfileInitalContainer
          setProfileState={setProfileState}
          userName={user}
          dcAbbrev={dc?.distribution_center?.routing_code}
          dcName={dc?.distribution_center?.name}
          lmcName={dc?.last_mile_company?.name}
          hasMoreMultipleChoices={hasMoreMultipleChoices}
          fetchDCFail={fetchDCFail}
        />
      )}
      {isChangeBase && (
        <ProfileChangeBaseContainer
          dcName={dc?.distribution_center?.name}
          lmcName={dc?.last_mile_company?.name}
          setProfileState={setProfileState}
          onChooseBase={onChooseBase}
          data={data}
        />
      )}
    </ProfileComponent>
  );
};

export default ProfileContainer;
