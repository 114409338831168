import React from 'react';
import PropTypes from 'prop-types';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { SWITCHES_REALTIME } from 'view/constants';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import DriversProfileInfo from './drivers-profile-info.component';
import DriversProfilePickup from './drivers-profile-pickup.component';
import DriversProfileDelivery from './drivers-profile-delivery.component';
import EditDriverButton from './drivers-profile-edit-button-component';
import DriversEditContainer from './drivers-profile-edit-component';

const DriverProfileComponent = ({
  open,
  hasDc,
  onClose,
  profile,
  onMigrate,
  onDelete,
  onPickupToggle,
  onDeliveryClick,
  onEdit,
  shouldEdit,
  setShouldEdit,
  setOpenProfile
}) => {
  const disableDriverProfilePickupInfo = IsFSActiveForCurrentBase(
    SWITCHES_REALTIME.disableDriverProfilePickupInfo
  );

  return shouldEdit ? (
    <DriversEditContainer
      open={shouldEdit}
      handleClosingDrawer={() => setShouldEdit(false)}
      profile={profile}
      onDelete={onDelete}
      onEdit={onEdit}
      setOpenProfile={setOpenProfile}
    />
  ) : (
    <CustomDrawer open={open}>
      <DrawerHeader showDivider handleClosingDrawer={onClose}>
        <DriversProfileInfo profile={profile} />
      </DrawerHeader>
      {!disableDriverProfilePickupInfo && (
        <DrawerContent showDivider>
          <DriversProfilePickup
            hasDc={hasDc}
            onMigrate={onMigrate}
            onToggle={onPickupToggle}
            profile={profile}
          />
        </DrawerContent>
      )}
      {hasDc && (
        <DrawerContent showDivider>
          <DriversProfileDelivery onClick={onDeliveryClick} profile={profile} />
        </DrawerContent>
      )}
      <DrawerContent showDivider>
        <EditDriverButton handleEditForm={() => setShouldEdit(true)} />
      </DrawerContent>
    </CustomDrawer>
  );
};

DriverProfileComponent.propTypes = {
  open: PropTypes.bool,
  hasDc: PropTypes.bool.isRequired,
  profile: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onDeliveryClick: PropTypes.func.isRequired,
  onMigrate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPickupToggle: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  shouldEdit: PropTypes.bool,
  setShouldEdit: PropTypes.func.isRequired,
  setOpenProfile: PropTypes.func.isRequired
};

DriverProfileComponent.defaultProps = {
  open: false,
  profile: {},
  shouldEdit: false
};

export default DriverProfileComponent;
