import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Skeleton } from '@material-ui/lab';
import { Box, Typography, IconButton } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { useStyles } from 'tracking/tracking.styles';
import { SummaryContext } from 'tracking/template/summary.context';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import {
  TITLE,
  TEXT,
  DISPUTE_DATE_FORMAT,
  ERROR_TEXT,
  DISPUTE_STATUS_PENDING
} from './disputes.constants';
import DisputesComponent from './disputes.component';
import { getDisputesData } from './disputes.service';
import objectToQueryString from './disputes-utils';

function DisputesContainer() {
  const { enqueueSnackbar } = useSnackbar();
  const [{ disputes = [], pagination = {} }, setDisputesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [delivererFilter, setDelivererFilter] = useState({});
  const [buttonsFilter, setButtonsFilter] = useState({});
  const [disputeFilters, setDisputeFilters] = useState({});
  const { setSummary } = useContext(SummaryContext);
  const classes = useStyles();
  const history = useHistory();
  const defaultEndDate = moment(new Date())
    .add(10, 'days')
    .format(DISPUTE_DATE_FORMAT);
  const defaultStartDate = moment(defaultEndDate)
    .subtract(40, 'days')
    .format(DISPUTE_DATE_FORMAT);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [disputeStatus] = useState(DISPUTE_STATUS_PENDING);

  const countPackageDisplay = count => {
    switch (count) {
      case 0:
        return TEXT.NONE_PACKAGE;
      case 1:
        return `${count} ${TEXT.PACKAGE}`;
      default:
        return `${count} ${TEXT.PACKAGE}s`;
    }
  };

  const [filterReport, setFilterReport] = useState(null);

  function updateDisputeFiltersByStatus(filters, status) {
    return `${objectToQueryString(filters)};${JSON.stringify({
      key: 'status',
      values: [status]
    })}`;
  }

  useEffect(() => {
    const disputeFiltersByStatus = updateDisputeFiltersByStatus(
      disputeFilters,
      disputeStatus
    );
    getDisputesData({
      startDate,
      endDate,
      currentPage,
      disputeFilters: disputeFiltersByStatus
    })
      .then(currentDisputesData => {
        setDisputesData(currentDisputesData);
        setTotalPackages(
          parseInt(currentDisputesData.pagination.numberDisputesAllPages, 10)
        );
        setLoading(false);
        setFilterReport({
          startDate,
          endDate,
          disputeFilters: disputeFiltersByStatus
        });
      })
      .catch(error => {
        showSnackbar({
          message: `${ERROR_TEXT} (${error.message})`,
          variant: 'error',
          enqueueSnackbar
        });
      });
  }, [
    setLoading,
    startDate,
    endDate,
    currentPage,
    disputeFilters,
    enqueueSnackbar,
    disputeStatus
  ]);

  useEffect(() => {
    const handleBackFinished = () => {
      const rc = getSelectedRoutingCode();
      history.push(`/${rc}${AUTHORIZED_ROUTES.TRACKING.FINISHED}`);
    };

    setSummary(
      <>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr={1}>
            <IconButton size="small" edge="start" onClick={handleBackFinished}>
              <Box
                color="primary.contrastText"
                display="flex"
                alignItems="center"
              >
                <ArrowBackRoundedIcon data-testid="back-button" />
              </Box>
            </IconButton>
          </Box>
          <Typography variant="h4" data-testid="title">
            {TITLE.finished}
            <strong>{TITLE.disputes}</strong>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h4">
            {loading ? (
              <Skeleton
                variant="rect"
                width="150px"
                className={classes.skeleton}
                height="42px"
              />
            ) : (
              countPackageDisplay(totalPackages)
            )}
          </Typography>
        </Box>
      </>
    );
  }, [setSummary, history, loading, totalPackages, classes.skeleton]);

  const createDisputeFilters = (firstFilter, secondFilter) => {
    const generalFilter = `${JSON.stringify(firstFilter)};${JSON.stringify(
      secondFilter
    )}`;
    setDisputeFilters(generalFilter);
  };

  const handlePagination = page => {
    setCurrentPage(page);
  };

  const handleDelivererFilter = items => {
    const deliverers = { ...items.deliverers };
    const deliverersIds = Object.values(deliverers).reduce(
      (a, b) => a.concat(b),
      []
    );
    const currentFilter = {
      key: 'deliverer',
      values: deliverersIds
    };
    setLoading(true);
    setCurrentPage(1);
    setDelivererFilter(currentFilter);
    createDisputeFilters(currentFilter, buttonsFilter);
  };

  const handleButtonFilters = (filterKey, filterValues) => {
    if (filterKey === 'range_date') {
      setStartDate(moment(new Date()).format(DISPUTE_DATE_FORMAT));
      setEndDate(
        moment(new Date())
          .add(1, 'days')
          .format(DISPUTE_DATE_FORMAT)
      );
    } else {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      const buttonFilter = {
        key: filterKey,
        values: filterValues
      };
      setButtonsFilter(buttonFilter);
      createDisputeFilters(delivererFilter, buttonFilter);
    }

    setLoading(true);
    setCurrentPage(1);
  };

  return (
    <DisputesComponent
      disputesData={disputes}
      loadingTable={loading}
      onPageChange={handlePagination}
      pagination={pagination}
      handleDelivererFilter={handleDelivererFilter}
      handleButtonFilters={handleButtonFilters}
      filterReport={filterReport}
    />
  );
}

export default DisputesContainer;
