import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import showSnackbar from 'shared/snackbar';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { useSnackbar } from 'notistack';
import { fetchTripsCancelAllocated } from '../../delivererAllocation/service';
import { CANCEL_DIALOG } from './constans';

const CancelAllocatedContainer = ({
  dataGroup,
  onCloseDrawer,
  triggerDelivererUpdate
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        data-testid="undo-allocation"
        onClick={() => setOpenDialog(true)}
      >
        {CANCEL_DIALOG.UNDO_DISTRIBUTION}
      </Button>

      <ConfirmDialogComponent
        open={openDialog}
        title={CANCEL_DIALOG.TITLE}
        description={CANCEL_DIALOG.DESCRIPTION}
      >
        <Button
          onClick={() => setOpenDialog(false)}
          color="primary"
          data-testid="confirmation-dialog-cancel-btn"
        >
          {CANCEL_DIALOG.CANCEL_BTN}
        </Button>
        <Button
          onClick={async () => {
            fetchTripsCancelAllocated(dataGroup.licensePlate)
              .then(() => {
                onCloseDrawer();
                triggerDelivererUpdate();
                showSnackbar({
                  message: 'Distribuição cancelada com sucesso',
                  variant: 'success',
                  enqueueSnackbar
                });
              })
              .catch(() => {
                showSnackbar({
                  message: 'Falha ao cancelar, tente novamente',
                  variant: 'error',
                  enqueueSnackbar
                });
              });
          }}
          color="primary"
          variant="contained"
          data-testid="confirmation-dialog-submit-btn"
        >
          {CANCEL_DIALOG.CONTINUE_BTN}
        </Button>
      </ConfirmDialogComponent>
    </>
  );
};

CancelAllocatedContainer.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape.isRequired,
  triggerDelivererUpdate: PropTypes.func.isRequired
};

export default CancelAllocatedContainer;
