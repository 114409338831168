import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SWITCHES_REALTIME } from 'view/constants';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import getDeliverers from 'app/get-deliverers';
import changeDelivererService from 'app/changeDeliverer';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { SEPARATION_CONTEXT } from 'tracking/drawer-group-packages/drawer-group-packages.constants';
import DrawerGroupPackagesContainer from 'tracking/drawer-group-packages/drawer-group-packages.container';
import { fetchTripsCheck } from 'tracking/configurable-table-collapsible/delivererAllocation/service';

import { CustomDrawer, DrawerHeader } from 'shared/drawer';
import {
  getUnitLoadInfo,
  movePackageInBulk
} from './drawer-group-actions.service';
import DrawerCirclesGroupActionsComponent from './drawer-circles-group-actions.component';
import DrawerDetailGroupActionsComponent from './drawer-detail-group-actions.component';
import DrawerDeliverersGroupActionsComponent from './drawer-deliverers-group-actions.component';
import DrawerPrintLabelGroupActionsComponent from './drawer-print-label-group-actions.component';
import TEXT, { SEP_CONTEXT, VIEWS_DRAWER } from './constants';

export default function DrawerGroupContainer({
  onCloseDrawer,
  dataGroup,
  callFetchGroupByUnitLoad,
  showViewActionsDrawer,
  doFetchGroupIsAllocated,
  triggerDelivererUpdate
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [showViewDrawer, setShowViewDrawer] = useState(showViewActionsDrawer);
  const [deliverers, setDeliverers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelivererAssignment, setLoadingDelivererAssignment] = useState(
    false
  );

  const fsRealtimeEnableFetchGroupItIsAllocatedInDrawer = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableFetchGroupItIsAllocatedInDrawer
  );

  const [loadingInfoDriverAllocated, setLoadingInfoDriverAllocated] = useState(
    true
  );
  const [infoDriverAllocated, setInfoDriverAllocated] = useState({});
  const [errorFetchInfoDriver, setErrorFetchInfoDriver] = useState(false);

  const routingCode = getSelectedRoutingCode();

  useEffect(() => {
    getDeliverers()
      .then(result => {
        setDeliverers(result);
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
  }, [enqueueSnackbar]);

  const handleFetchTripsCheck = useCallback(async () => {
    setErrorFetchInfoDriver(false);
    setLoadingInfoDriverAllocated(true);
    fetchTripsCheck(dataGroup.licensePlate)
      .then(result => {
        setInfoDriverAllocated(result);
        setLoadingInfoDriverAllocated(false);
      })
      .catch(() => {
        setLoadingInfoDriverAllocated(false);
        setErrorFetchInfoDriver(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fsRealtimeEnableFetchGroupItIsAllocatedInDrawer &&
      doFetchGroupIsAllocated
    )
      handleFetchTripsCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsRealtimeEnableFetchGroupItIsAllocatedInDrawer]);

  const handleDelivererAssignment = async delivererId => {
    try {
      setLoadingDelivererAssignment(true);
      const ul = await getUnitLoadInfo(dataGroup.licensePlate);
      const packageIds = ul.childrenUnitLoad.map(p => p.id);

      if (packageIds.length > 100)
        showSnackbar({
          withButton: false,
          message: TEXT.DELIVERER_ASSOCIATION_SIZE_WARNING,
          variant: 'warning',
          enqueueSnackbar
        });

      await changeDelivererService(delivererId, packageIds);

      setLoadingDelivererAssignment(false);
      onCloseDrawer();
      triggerDelivererUpdate();
      showSnackbar({
        withButton: false,
        message: TEXT.DELIVERER_ASSOCIATION_SUCCESS,
        variant: 'success',
        enqueueSnackbar
      });
    } catch (error) {
      setLoadingDelivererAssignment(false);
      showSnackbar({
        message: error.message,
        variant: 'error',
        enqueueSnackbar
      });
    }
  };

  const handleClickDisassociation = async () => {
    try {
      setLoading(true);
      const ul = await getUnitLoadInfo(dataGroup.licensePlate);
      const packageIds = ul.childrenUnitLoad.map(p => p.id);
      await movePackageInBulk({
        packageIds,
        sortingContextLpn: `${routingCode} ${SEP_CONTEXT}`
      });

      setLoading(false);
      onCloseDrawer();
      showSnackbar({
        withButton: false,
        message: TEXT.DISASSOCIATION_SUCCESS,
        variant: 'success',
        enqueueSnackbar
      });
    } catch (error) {
      setLoading(false);
      onCloseDrawer();
      showSnackbar({
        message: error.message,
        variant: 'error',
        enqueueSnackbar
      });
    }
  };

  return (
    <>
      {showViewDrawer === VIEWS_DRAWER.DETAIL &&
        (doFetchGroupIsAllocated &&
        fsRealtimeEnableFetchGroupItIsAllocatedInDrawer &&
        (loadingInfoDriverAllocated || errorFetchInfoDriver) ? (
          <CustomDrawer
            open
            loading={loadingInfoDriverAllocated}
            textError="Tente de novo"
            error={errorFetchInfoDriver}
            callbackRetry={handleFetchTripsCheck}
          >
            <DrawerHeader
              handleClosingDrawer={onCloseDrawer}
              showDivider={false}
            />
          </CustomDrawer>
        ) : (
          <DrawerDetailGroupActionsComponent
            onCloseDrawer={onCloseDrawer}
            loadingDisassociation={loading}
            dataGroup={dataGroup}
            handleViewCirclesDrawer={() =>
              setShowViewDrawer(VIEWS_DRAWER.CIRCLES)
            }
            handleViewDeliverersDrawer={() =>
              setShowViewDrawer(VIEWS_DRAWER.DELIVERER)
            }
            handleViewEditGroup={() =>
              setShowViewDrawer(VIEWS_DRAWER.EDIT_GROUP)
            }
            handleViewEditKeywords={() =>
              setShowViewDrawer(VIEWS_DRAWER.EDIT_KEYWORDS)
            }
            handleHeaderClick={handleClickDisassociation}
            infoDriverAllocated={infoDriverAllocated}
            loadingInfoDriverAllocated={loadingInfoDriverAllocated}
            fsRealtimeEnableFetchGroupItIsAllocatedInDrawer={
              fsRealtimeEnableFetchGroupItIsAllocatedInDrawer
            }
            triggerDelivererUpdate={triggerDelivererUpdate}
          />
        ))}

      {showViewDrawer === VIEWS_DRAWER.CIRCLES && (
        <DrawerCirclesGroupActionsComponent
          onBackDetailDrawer={() => setShowViewDrawer(VIEWS_DRAWER.DETAIL)}
          onCloseDrawer={onCloseDrawer}
          dataGroup={dataGroup}
          callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
        />
      )}

      {showViewDrawer === VIEWS_DRAWER.DELIVERER && (
        <DrawerDeliverersGroupActionsComponent
          onCloseDrawer={onCloseDrawer}
          dataGroup={dataGroup}
          deliverers={deliverers}
          onConfirmChange={handleDelivererAssignment}
          loadingDelivererAssignment={loadingDelivererAssignment}
          infoDriverAllocated={infoDriverAllocated}
          fsRealtimeEnableFetchGroupItIsAllocatedInDrawer={
            fsRealtimeEnableFetchGroupItIsAllocatedInDrawer
          }
        />
      )}

      {showViewDrawer === VIEWS_DRAWER.PRINT_LABEL && (
        <DrawerPrintLabelGroupActionsComponent
          onCloseDrawer={onCloseDrawer}
          dataGroup={dataGroup}
        />
      )}

      {showViewDrawer === VIEWS_DRAWER.EDIT_GROUP && (
        <DrawerGroupPackagesContainer
          routingCode={routingCode}
          destinationContext={SEPARATION_CONTEXT}
          handleClosingDrawer={onCloseDrawer}
          licensePlateToEdit={dataGroup.licensePlate}
        />
      )}

      {showViewDrawer === VIEWS_DRAWER.EDIT_KEYWORDS && (
        <DrawerGroupPackagesContainer
          routingCode={routingCode}
          destinationContext={SEPARATION_CONTEXT}
          handleClosingDrawer={onCloseDrawer}
          licensePlateToEdit={dataGroup.licensePlate}
          initialViewDrawer={VIEWS_DRAWER.KEYWORDS}
          packageGroupId={dataGroup.packageGroupId}
          keywordsToEdit={dataGroup.keywords}
        />
      )}
    </>
  );
}

DrawerGroupContainer.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number,
    circleName: PropTypes.string,
    circleId: PropTypes.string,
    packageGroupId: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  callFetchGroupByUnitLoad: PropTypes.func.isRequired,
  showViewActionsDrawer: PropTypes.string,
  doFetchGroupIsAllocated: PropTypes.bool,
  triggerDelivererUpdate: PropTypes.func.isRequired
};

DrawerGroupContainer.defaultProps = {
  showViewActionsDrawer: VIEWS_DRAWER.DETAIL,
  doFetchGroupIsAllocated: false
};
