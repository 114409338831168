import getLastMilePackages from 'infra/service/get-last-mile-packages';
import { BLOCK_PACKAGE_IS_ROUTED } from 'tracking/configurable-table-collapsible/drawer-group-actions/allocated/constans';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Nenhum pacote encontrado para este barcode',
  500: 'Falha ao consultar os pacotes deste barcode, tente novamente mais tarde'
};

const getLastMilePackagesByBarcode = barcode =>
  getLastMilePackages({ barcode })
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.data?.includes(BLOCK_PACKAGE_IS_ROUTED.error)) {
        throw new Error(BLOCK_PACKAGE_IS_ROUTED.message);
      } else {
        throw new Error(
          error.response
            ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
            : DEFAULT_MESSAGE
        );
      }
    });

export default getLastMilePackagesByBarcode;
