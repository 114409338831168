import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Divider, Fab, Typography, Avatar } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecord';
import { ArrowBackRounded } from '@material-ui/icons';
import useStylesDrawer from 'view/molecules/package-drawer/drawer.style';
import useStyles from './drawer-header.styles';

const ICON_SIZE = 18;

export default function DrawerHeader({
  status,
  title,
  subtitle,
  detail,
  showDivider,
  handleClosingDrawer,
  children,
  colorText,
  avatar,
  variantTextDetail,
  open,
  titleVariant,
  headerTitle
}) {
  const classes = useStyles();
  const classesDrawerMolecules = useStylesDrawer();

  return (
    <>
      <Box px={3.5}>
        <Box mt={2} py={1} mb={2}>
          <Fab
            size="small"
            data-testid="closed-drawer"
            onClick={handleClosingDrawer}
          >
            {open ? (
              <ArrowBackRounded color="primary" />
            ) : (
              <CloseRoundedIcon color="primary" />
            )}
          </Fab>
        </Box>
        <Box pt={1} pb={2}>
          {status && status.type === 'component' && status.component()}
          {status && status.type === 'chip' && (
            <Box py={2.5}>
              <Chip size="small" label={status.label} disabled />
            </Box>
          )}
          {title && avatar && (
            <Avatar className={classes.avatar}>{title[0].toUpperCase()}</Avatar>
          )}
          {status && status.type === 'dot' && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <FiberManualRecordRoundedIcon
                style={{
                  color: status.dotColor,
                  fontSize: ICON_SIZE
                }}
              />
              <Box pl={1} pr={2}>
                <Typography noWrap variant="body2">
                  {status.label}
                </Typography>
              </Box>
            </Box>
          )}
          {headerTitle && (
            <Typography
              variant="body2"
              className={classesDrawerMolecules.smoke}
            >
              {headerTitle}
            </Typography>
          )}
          {title && (
            <Box pt={1.5}>
              <Typography variant={titleVariant}>
                <strong>{title}</strong>
              </Typography>
            </Box>
          )}
          {subtitle && (
            <Box pt={2}>
              {subtitle.split('\\n').map(text => {
                return (
                  <Typography key={text} variant="h5">
                    {text}
                  </Typography>
                );
              })}
            </Box>
          )}
          {detail && (
            <Box pt={2}>
              {detail.split('\\n').map(text => {
                return (
                  <Typography
                    key={text}
                    variant={variantTextDetail}
                    color={colorText}
                  >
                    {text}
                  </Typography>
                );
              })}
            </Box>
          )}
          {children}
        </Box>
      </Box>
      {showDivider && <Divider data-testid="drawer-header-divider" />}
    </>
  );
}

DrawerHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  detail: PropTypes.string,
  status: PropTypes.shape({
    type: PropTypes.oneOf(['chip', 'dot', 'component']),
    label: PropTypes.string,
    dotColor: PropTypes.string,
    component: PropTypes.func
  }),
  showDivider: PropTypes.bool,
  handleClosingDrawer: PropTypes.func,
  children: PropTypes.node,
  colorText: PropTypes.string,
  avatar: PropTypes.bool,
  variantTextDetail: PropTypes.string,
  open: PropTypes.bool,
  titleVariant: PropTypes.string,
  headerTitle: PropTypes.string
};

DrawerHeader.defaultProps = {
  title: null,
  subtitle: null,
  detail: null,
  status: null,
  showDivider: true,
  handleClosingDrawer: () => {},
  children: null,
  colorText: 'textSecondary',
  avatar: false,
  variantTextDetail: 'subtitle2',
  open: false,
  titleVariant: 'h3',
  headerTitle: null
};
