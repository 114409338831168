import React from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import { get } from 'tracking/tracking.configuration';
import { formatDate } from 'finance/finance.configuration';
import { colors } from '@loggi/mar';
import moment from 'moment';
import { MAPPER_STATUS } from './disputes.constants';

function isExpiring(date) {
  const deadlineDate = moment(date);
  const now = moment();
  const oneDayFromNow = moment().add(24, 'hours');

  return deadlineDate.isBetween(now, oneDayFromNow);
}

const deadline = {
  header: 'Resolver até',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => formatDate(item?.deadline, 'DD MMM [às] HH:mm')
};

const deliverer = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'deliverer', '-')
};

const renderDisputeResponsible = item => {
  const disputeResponsibleName = get(item, 'disputeResponsible', false);
  // eslint-disable-next-line no-unneeded-ternary
  return (
    disputeResponsibleName || (
      <Typography style={{ color: colors.root[500] }} variant="body2">
        ninguém alocado
      </Typography>
    )
  );
};

const disputeResponsible = {
  header: 'Quem vai resolver',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: renderDisputeResponsible
};

const renderPackageBarcode = item => {
  const companyName = get(item, 'packageCompanyName', '-');
  const barcode = get(item, 'packageBarcode', '-');

  return (
    <Box>
      <Box>{companyName}</Box>
      <Typography style={{ color: colors.root[500] }} variant="caption">
        {barcode}
      </Typography>
    </Box>
  );
};

const packageBarcode = {
  header: 'Pacote',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: renderPackageBarcode
};

const translatedStatus = {
  header: '',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    let status = get(item, 'translatedStatus', '-');

    if (status === 'Dispute Pending') {
      const itemDeadline = get(item, 'deadline', '');
      if (isExpiring(itemDeadline)) {
        status = 'Dispute Expiring';
      }
    }

    const statusMap = MAPPER_STATUS[status];

    return statusMap ? (
      <Chip style={statusMap.style} size="small" label={statusMap.text} />
    ) : (
      <></>
    );
  }
};

const openIn = {
  header: 'Aberto em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => formatDate(item?.created, 'DD MMM [às] HH:mm')
};

export default {
  openIn,
  packageBarcode,
  deliverer,
  disputeResponsible,
  deadline,
  translatedStatus
};
