import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  CreateNewPassword,
  ImpersonationForm
} from '@loggi/authentication/src/screens';

import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import {
  getSelectedRoutingCode,
  getLoggedDCId,
  getLoggedLMCId,
  getLoggedLMCCompanyType
} from 'auth/login/login.service';
import { WAS_LOGGED_OUT } from 'auth/login/login.constants';
import { fetchAndForceOrSelectFirst } from 'profile/profile.service';
import CanRouteTemplate from 'shared/can-route-template';
import { AlertPlatformDegradedComponent } from 'shared/alert';
import ImpersonateBanner from 'auth/impersonate-banner';
import Performance from 'performance';
import Menu from 'menu';

import FirebaseCloudMessagingContainer from 'infra/firebase/firebase-cloud-messaging.container';
import DialogNotificationsComponent from 'information/notifications/dialog/dialog.component';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { getRole } from 'auth/access-control/access-control.service';
import { AUTHORIZED_ROUTES, AUTHENTICATED_ROUTES, SWITCHES } from './constants';
import AuthorizedRoutes from './routes_authorized';
import {
  hotjarIdentifyScript,
  transformCognitoInfoToMap
} from './routes_authenticated.service';

const AUTHORIZED_STATES = {
  LOADING: 0,
  AUTHORIZED: 1
};
Object.freeze(AUTHORIZED_STATES);

export default function AuthenticatedRoutes() {
  const [authorized, setAuthorized] = useState(AUTHORIZED_STATES.LOADING);
  const {
    signOut,
    state: { isImpersonation, loading, newPasswordRequired, authenticatedUser }
  } = useAmplifyAuth();

  const { enablePerformanceInfo, enableFirebaseCloudMessaging } = useContext(
    FeatureSwitchContext
  );

  const enableNotifications = IsFSActiveForCurrentBase('enableNotifications');

  const { enableUserRoleOnHotjar } = useContext(FeatureSwitchContext);

  const showAlertPlatformDegradedComponent = IsFSActiveForCurrentBase(
    SWITCHES.showAlertPlatformDegradedComponent
  );

  const history = useHistory();

  // extract base identification from url
  // ex: history.location.pathname = /LT0/acompanhamento/na-rua
  // urlBaseIdentification = 'LTO'
  const urlBaseIdentification = history.location.pathname
    .substring(1)
    .split('/')[0];

  useEffect(() => {
    // useffect to handle redirect to nextPage
    // the useAmplifyAuth hook has a loading state for amplify.
    // We have to wait its loading state to finish to check
    // if the user is either logged with AWS Cognito or Leve's jwt
    if (!loading && !newPasswordRequired && authenticatedUser) {
      // If the user is authenticated we can redirect if necessary
      fetchAndForceOrSelectFirst(urlBaseIdentification)
        .then(async data => {
          if (data.lastmilecompanyDistributioncenter) {
            // fetch selectedBase
            const base = getSelectedRoutingCode();
            try {
              const lmc = getLoggedLMCId();
              const dc = getLoggedDCId();
              const userRole = enableUserRoleOnHotjar
                ? await getRole(lmc, dc)
                : null;
              const userInfo = transformCognitoInfoToMap(
                authenticatedUser,
                getLoggedLMCCompanyType(),
                lmc,
                dc,
                userRole
              );
              hotjarIdentifyScript(authenticatedUser?.sub, userInfo);
            } catch (error) {
              Sentry.captureException(error);
            }

            const isDifferentBase = urlBaseIdentification !== base;
            const isPathNotFromAuthenticateRoutes =
              urlBaseIdentification !==
              AUTHENTICATED_ROUTES.IMPERSONATE.substring(1);

            // if is a isDifferentBase or the route isnt from a base related one redirect to HOME
            if (isDifferentBase && isPathNotFromAuthenticateRoutes) {
              history.push(
                `/${base}${AUTHORIZED_ROUTES.INFORMATION.BASE}${
                  AUTHORIZED_ROUTES.INFORMATION.COLLECTION
                }`,
                base
              );
            }
            setAuthorized(AUTHORIZED_STATES.AUTHORIZED);
          } else {
            await signOut();
            localStorage.setItem(WAS_LOGGED_OUT.KEY, true);
            window.location.reload();
          }
        })
        .catch(async () => {
          await signOut();
          window.location.reload();
        });
    }
  }, [
    loading,
    urlBaseIdentification,
    authenticatedUser,
    newPasswordRequired,
    enableUserRoleOnHotjar,
    isImpersonation,
    history,
    signOut
  ]);

  if (authorized === AUTHORIZED_STATES.LOADING && !newPasswordRequired) {
    return null;
  }

  return (
    <>
      {enableFirebaseCloudMessaging && enableNotifications && (
        <>
          <FirebaseCloudMessagingContainer />
          <DialogNotificationsComponent />
        </>
      )}

      {showAlertPlatformDegradedComponent && <AlertPlatformDegradedComponent />}

      <ImpersonateBanner />
      {enablePerformanceInfo && <Performance />}
      <Menu />

      {/* force newPassword if required */}
      {newPasswordRequired && (
        <Switch>
          <Route
            path={AUTHENTICATED_ROUTES.CREATE_NEW_PASSWORD_ROUTE}
            component={CreateNewPassword}
          />

          <Redirect to={AUTHENTICATED_ROUTES.CREATE_NEW_PASSWORD_ROUTE} />
        </Switch>
      )}

      {!newPasswordRequired && (
        <Switch>
          {!isImpersonation && authenticatedUser && (
            <CanRouteTemplate
              exact
              path={AUTHENTICATED_ROUTES.IMPERSONATE}
              component={ImpersonationForm}
            />
          )}

          {/* redirect after impersonate */}
          {isImpersonation && (
            <Redirect
              from={AUTHENTICATED_ROUTES.IMPERSONATE}
              to={AUTHENTICATED_ROUTES.IMPERSONATE_REDIRECT}
            />
          )}

          {authorized === AUTHORIZED_STATES.AUTHORIZED && (
            <Route path="/:routingCode" component={AuthorizedRoutes} />
          )}
        </Switch>
      )}
    </>
  );
}
