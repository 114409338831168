import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { Box, Button, Grid, Popover, Typography } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import ConfirmDialog from 'shared/confirm-dialog';
import TEXT from './button-delivery.constants';
import AvatarListComponent from '../avatar-list/avatar-list.component';
import { getDriversList, assignDriverPickup } from './button-delivery.service';

export default function ButtonPopoverAssignOfferComponent({ offerId }) {
  const { enqueueSnackbar } = useSnackbar();
  const enableAllocateDriver = IsFSActiveForCurrentBase('enableAllocateDriver');

  const [drivers, setDrivers] = useState([]);
  const [allocatedDrive, setAllocatedDriver] = useState('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  useEffect(() => {
    if (enableAllocateDriver) {
      getDriversList()
        .then(response => {
          if (response) {
            setDrivers(response.data.availableDriversInfo);
          }
        })
        .catch(error => {
          showSnackbar({
            message: `${TEXT.LABEL_ERROR_GET_DRIVER_LIST} (${
              error.response.data.message
            })`,
            variant: 'error',
            enqueueSnackbar
          });
        });
    }
  }, [enableAllocateDriver, enqueueSnackbar]);

  const handleChangeDriver = driverId => {
    setAllocatedDriver(driverId);
  };

  const handleSave = (allocatedDriver, allDrivers, offer) => {
    const driver = allDrivers.find(
      availableDrivers =>
        availableDrivers.driverOfferInfo.id === allocatedDriver
    );

    assignDriverPickup(driver.driverOfferInfo, offer)
      .then(() => {
        showSnackbar({
          message: TEXT.LABEL_SUCCESS_DRIVER_ALOCATION,
          variant: 'success',
          enqueueSnackbar
        });
      })
      .catch(error => {
        if (error?.response?.status) {
          try {
            let messageError = error?.response?.data?.message;
            if (messageError.includes(':')) {
              messageError = messageError.split(':');
              messageError = messageError[[messageError.length - 1]];
            }
            showSnackbar({
              message: `${
                TEXT.LABEL_ERROR_ASSIGN_DRIVER
              } [Error:(${messageError})]`,
              variant: 'error',
              enqueueSnackbar
            });
          } catch (e) {
            showSnackbar({
              message: `${TEXT.LABEL_ERROR_ASSIGN_DRIVER} [exception]`,
              variant: 'error',
              enqueueSnackbar
            });
          }
        }
        showSnackbar({
          message: `${TEXT.LABEL_ERROR_ASSIGN_DRIVER} [assign-default]`,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  const driversList = drivers.length ? (
    <AvatarListComponent
      handleChangeDriver={handleChangeDriver}
      drivers={drivers}
    />
  ) : (
    <Box m={2}>
      <Typography variant="subtitle2">
        {TEXT.LABEL_NO_DRIVER_AVAILABLE}
      </Typography>
    </Box>
  );

  return (
    <>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={TEXT.LABEL_TITLE_DIALOG}
        description={TEXT.LABEL_DESCRIPTION_DIALOG}
      >
        <Button
          color="secondary"
          size="large"
          onClick={() => setIsConfirmDialogOpen(false)}
        >
          {TEXT.BTN_ASSIGN_CANCEL}
        </Button>
        <Button
          data-testid="buttonHandleSave"
          color="secondary"
          variant="contained"
          onClick={() => {
            setIsConfirmDialogOpen(false);
            handleSave(allocatedDrive, drivers, offerId);
          }}
        >
          {TEXT.BTN_CONFIRM_ASSIGN}
        </Button>
      </ConfirmDialog>

      <Box mr={2} component="span">
        {enableAllocateDriver && (
          <Button
            data-testid="btn-assign-offer-to-deliverer"
            variant="outlined"
            color="primary"
            onClick={handleClick}
            size="small"
          >
            {TEXT.BTN_ASSIGN_DRIVER}
            {!open && <ArrowDropDownIcon />}
            {open && <ArrowDropUpIcon />}
          </Button>
        )}
        <Popover
          data-testid="popover-wrapper"
          open={open}
          anchorEl={anchor}
          onClose={() => setAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          PaperProps={{
            style: {
              width: 400
            }
          }}
        >
          <Box
            maxHeight="300px"
            maxWidth="400px"
            data-testid="filter-content-wrapper"
            style={{ overflowX: 'hidden' }}
          >
            {driversList}
          </Box>

          <footer>
            <Box m={3}>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={() => setAnchor(null)}
                  >
                    {TEXT.BTN_ASSIGN_CANCEL}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    data-testid="btn-confirm-offer-to-deliverer"
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={drivers.length === 0}
                    onClick={() => setIsConfirmDialogOpen(true)}
                  >
                    {TEXT.BTN_CONFIRM_ASSIGN}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </footer>
        </Popover>
      </Box>
    </>
  );
}

ButtonPopoverAssignOfferComponent.propTypes = {
  offerId: PropTypes.string.isRequired
};
